html
{
  padding: 0;
  margin: 0;
}

body
{
  font-family: sans-serif;
  font-size: 11pt;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  touch-action: manipulation;
  margin: 0;
  background: #f0faff;
}

#content
{
  margin: 0.5em;
}

a
{
  color: blue;
}

table
{
  background: white;
  border-collapse: collapse;
  margin-bottom: 1em;
}

table th, table td
{
  border: solid 1px gray;
}

table > tbody > tr > th
{
  text-align: left;
}

table > tbody > tr.active
{
  background: cyan;
}

table > thead > tr > th
{
  background: #667788;
  color: #e5e5e5;
}

table > tfoot > tr > th
{
  padding: 0;
  background: #667788;
  color: #e5e5e5;
}

table > caption
{
  background: #445566;
  color: white;
  font-weight: bold;
}

#menus > ul
{
  display: none;
  width: 200px;
  padding: 6px;
  background: silver;
  border: solid 1px gray;
  border-radius: 8px;
  box-shadow: 0 0 6px #999;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;  
  li
  {
    cursor: pointer;
    display: block;
    list-style: none;
    margin: 0.375em;
    &:hover
    {
      background: #a0a0a0;
    }
    &.add
    {
      color: green;
    }
    &.delete
    {
      color: red;
    }
    &.space
    {
      font-size: 0.5em;
      &:hover
      {
        cursor: default;
        background: none;
      }
    }
  }
}

i
{
  margin-left: 0.25em;
  font-size: 0.85em;
  font-style: normal;
}

body > header
{
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  a
  {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    padding: 0.25em;
    margin: 0;
  }
}

#main_header
{
  background: #334455;
  color: white;
}

.pager
{
  background: white;
  span,a
  {
    display: inline-block;
    margin: 0.125em;
    border: solid 1px silver;
    border-radius: 8px;
    padding: 0.125em 0.25em;
  }
  a
  {
    text-decoration: none;
    background:  #667788;
    color: #e5e5e5;
  }
  .active
  {
     background: #334455;
     color: white;
  }
}

#page_tabs
{
  a
  {
    margin-right: 0.125em;
    font-size: 1.1em;
    height: 19px;
    color: white;
    border-bottom: solid 2px #5588dd;
    &:hover
    {
      border-bottom: solid 2px #99ccff;
    }
    .rowcount
    {
      padding: 0.125em;
      border-radius: 6px;
      background: #112233;
      color: silver !important;
      font-size: 0.75em;
    }
    i
    {
      color: silver;
      &:hover
      {
        color: cyan;
      }
    }
    &.active
    {
      color: white;
      border-bottom: solid 2px #99ccff;
      &:hover
      {
        color: yellow;
      }
    }
  }

  #listing_submenu
  {
    display: inline-block;
  }

  #show_listing_tabs
  {
    display: none;
  }

  #listing_tabs
  {
    display: inline-block;
  }

  #tool_tabs
  {
    display: inline-block;
    i
    {
      display: inline-block;
      padding: 0.25em;
      cursor: pointer;
      margin: 0.125em;
      font-size: 1.1em;
      color: white;
      &.active
      {
        background: #445566;
        border-radius: 6px;
      }
    }
  }

}

@media screen and (max-width: 768px)
{
  #page_tabs
  {
    a
    {
      height: auto;
    }

    #show_listing_tabs
    {
      display: inline;
      font-size: 1.3em;
      border-bottom: none !important;
    }

    #listing_tabs
    {
      display: none;
      border: solid 1px black;
      position: absolute;
      background: #334455;
      a
      {
        display: block;
        border-bottom: none !important;
        &.active
        {
          color: yellow;
          border-bottom: none !important;
        }
        &:hover
        {
          color: cyan;
          border-bottom: none !important;
        }
      }
    }

    #listing_submenu:hover
    {
      #show_listing_tabs
      {
        color: yellow;
      }
      #listing_tabs
      {
        display: block;
      }
    }
  }
}

#user_menu
{
  vertical-align: top;
  margin-right: 0.25em;

  .submenu
  {
    display: inline-block;
    margin-right: 0.5em;
  }

  .submenu > .button
  {
    display: inline-block;
    cursor: pointer;
    font-size: 1.3em;
    &.help
    { // You'll need to override this.
      display: none;
    }
  }
  .submenu > .items
  {
    max-height: 20em;
    overflow: auto;
    border: solid 1px black;
    right: 0;
    position: absolute;
    display: none;
    background: white !important;
    a
    {
      margin: 0;
      padding: 0.5em;
      display: block;
      border: none !important;
      color: black !important;
      &.active
      {
        color: black !important;
        font-weight: bold;
      }
      &:hover
      {
        color: blue !important;
      }
    }
  }

  .submenu:hover
  {
    > .button
    {
      color: cyan;
    }
    > .items
    {
      display: block;
    }
  }

}

#sub_header
{
  padding: 0.125em;
  background: #112233;
  color: white;
  a
  {
    margin: 0.125em;
    color: white;
    border: solid 1px silver;
    background: #334455;
    border-radius: 8px;
    &:hover
    {
      background: #667788;
    }
    &.active
    {
      color: yellow;
    }
    i
    {
      color: silver;
      &:hover
      {
        color: red;
      }
    }
  }
}

body > footer
{
  font-size: 0.75em;
  font-weight: bold;
  color: gray;
  margin: 0.5em;
  text-align: center;
}

.right
{
  text-align: right;
}

#modals > div 
{
	display: none;
	padding: 0;
	background: white;
  color: black;
	//border: #7b7b7b solid 2px;
	border-radius: 8px;
	box-shadow: 0 0 6px #444;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 10;
  > header
  {
    text-align: center;
    border-radius: 6px 6px 0 0;
    padding: 0.25em;
    background: #445566;
    color: white;
    font-weight: bold;
  }
  > .content
  {
    margin: 0.25em;
    min-height: 8em;
    max-height: 32em;
    min-width: 32em;
    max-width: 48em;
    overflow: auto;
  }
  > footer
  {
    background: #667788;
    border-radius: 0 0 6px 6px;
    padding: 0.25em;
    display: flex;
    justify-content: space-between;
    input[type="button"], button
    {
      display: inline-block;
      margin: 0.125em;
      padding: 0.25em;
      border: none;
      border-radius: 6px;
      background: #c0c0d0;
      color: black;
      &:hover
      {
        background: #e0e0f0;
      }
    }
  }
}

#mask 
{
	display: none;
  min-height: 100%;
  height: auto !important;
	height: 100%; /* fallthrough for IE */
	width: 100%;
	padding: 0;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 5;
	background: gray;
	opacity: 0.50;
	filter: alpha(opacity=50);
}

